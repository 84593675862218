import React from 'react';
import { useNavigate, Link } from "react-router-dom"; // ✅ Importamos useNavigate y Link
import aicdi from '../images/AICDI.png';
import agricultura from '../images/agri.jpeg';
import fabric from '../images/fabri.jpg';
import cons from '../images/constru.avif';
import emprende from '../images/emprendiendo.jpg';
import buros from '../images/buro.png';
import blan from '../images/blanco.png';
import aic from '../images/AICDI-1.png';
import cnbv from '../images/cnbv.png';
import '../components/estilos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Crecifin = () => {
    return (
        <div>
            {/* Header */}
            <div className="header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <Link to="/" className="dropdown-item">
                                <img src={aicdi} height="120px" alt="Logo AICDI" />
                            </Link>
                            <button
                                className="navbar-toggler ms-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>

                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Productos
                                    </a>
                                    <ul className="dropdown-menu"></ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Simulador de Crédito
                                    </a>
                                    <ul className="dropdown-menu"></ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Industrias
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><Link to="/agricola" className="dropdown-item">Agrícola</Link></li>
                                        <li><Link to="/construccion" className="dropdown-item">Construcción</Link></li>
                                        <li><Link to="/emprendimiento" className="dropdown-item">Emprendimiento</Link></li>
                                        <li><Link to="/fabrica" className="dropdown-item">Fabricación</Link></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Cónocenos
                                    </a>
                                    <ul className="dropdown-menu"></ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            {/* Fin de Header */}
            {/* Carrusel de Bootstrap */}
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active position-relative" data-bs-interval="3000">
                        <img src={agricultura} className="d-block w-100" height="700px" width="100%" alt="Imagen 1" />
                        <h1 className="carousel-text">PRÓXIMAMENTE</h1>
                        <p className="carousel-text-1">© 2025 ASESORIA INTEGRAL PARA EL CRECIMIENTO Y DESARROLLO INDUSTRIAL.</p>
                    </div>
                    <div className="carousel-item position-relative" data-bs-interval="3000">
                        <img src={fabric} className="d-block w-100" height="700px" alt="Imagen 2" />
                        <h1 className="carousel-text">PRÓXIMAMENTE</h1>
                        <p className="carousel-text-1">© 2025 ASESORIA INTEGRAL PARA EL CRECIMIENTO Y DESARROLLO INDUSTRIAL.</p>
                    </div>
                    <div className="carousel-item position-relative" data-bs-interval="3000">
                        <img src={cons} className="d-block w-100" height="700px" alt="Imagen 3" />
                        <h1 className="carousel-text">PRÓXIMAMENTE</h1>
                        <p className="carousel-text-1">© 2025 ASESORIA INTEGRAL PARA EL CRECIMIENTO Y DESARROLLO INDUSTRIAL.</p>
                    </div>
                    <div className="carousel-item position-relative" data-bs-interval="3000">
                        <img src={emprende} className="d-block w-100" height="700px" alt="Imagen 4" />
                        <h1 className="carousel-text">PRÓXIMAMENTE</h1>
                        <p className="carousel-text-1">© 2025 ASESORIA INTEGRAL PARA EL CRECIMIENTO Y DESARROLLO INDUSTRIAL.</p>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='footer'>

                <div className='footer-row'>
                    <div className='footer-column' >
                        <div className='footer-row'>
                            <div className='footer-column' style={{ textAlign: 'initial' }}>
                                <img src={aic} height={"60px"} style={{ cursor: 'pointer' }} />
                                <br />
                                <p style={{ fontSize: '18px', marginTop: '10px' }}>Av. Cordillera de los Andes #120 Int. PB1
                                </p>
                                <p style={{ fontSize: '18px', marginTop: '1px' }}>
                                    Lomas de Chapultepec I Sección
                                </p>
                                <p style={{ fontSize: '18px', marginTop: '1px' }}>
                                    C.P. 11000 Miguel Hidalgo, CDMX
                                </p>
                            </div>


                        </div>
                    </div>
                    <div className='footer-column'>
                        <div className='footer-row'>
                            <div className='footer-column' style={{ width: '10px' }}>

                            </div>
                            <div className='footer-column' style={{ textAlign: 'initial', marginTop: '20px' }}>
                                <h4 style={{ color: '#F3C807', fontSize: '20px' }}>CONTÁCTO</h4>
                                <p style={{ fontSize: '18px' }}>WhatsApp: 55 6157 9643</p>
                                <p style={{ fontSize: '18px', width: '300px' }}>Lunes a Viernes: 08:00 - 17:00</p>
                                <p style={{ fontSize: '18px' }}>contacto@crecifin.com.mx</p>
                            </div>
                            <div className='footer-column' style={{ width: '10px' }}>
                            </div>
                        </div>

                    </div>
                    <div className='footer-column'>
                        <div className='buro'>
                            <img src={buros} height={"60px"} style={{ cursor: 'pointer' }} alt="buro" onClick={() => window.open('https://www.buro.gob.mx/')} />
                            <img src={blan} height={"60px"} style={{ cursor: 'pointer' }} alt="blanco" onClick={() => window.open('https://www.condusef.gob.mx/')} />
                            <br />
                            <img src={cnbv} height={"150px"} style={{ cursor: 'pointer' }} alt="blanco" onClick={() => window.open('https://www.gob.mx/cnbv')} />

                        </div>
                    </div>
                </div>
                <div className='footer-row'>
                    <div className='footer-links'>
                        <p className='footer-link'>Aviso de Privacidad</p>
                        <p className='footer-link'>Términos y condiciones</p>
                        <p className='footer-link'>
                            <Link to="/une" className='footer-link-inner'>UNE/BEF</Link>
                        </p>
                    </div>
                </div>
                <div className='desc'>
                    <p className='AICDI' style={{ textAlign: 'justify', color: '#000000' }} >Asesoria Integral para el Crecimiento y Desarrollo Industrial, Sociedad Anónima Promotora de Inversión de Capital Variable, Sociedad Financiera de
                        Objeto Múltiple, Entidad No Regulada, para su constitución y operación con tal carácter, no requiere de
                        autorización de la Secretaría de Hacienda y Crédito Público, no obstante, se encuentra sujeta a la supervisión
                        de la Comisión Nacional Bancaria y de Valores, únicamente para efectos de lo dispuesto por el artículo 56 de la
                        Ley General de Organizaciones y Actividades Auxiliares del Crédito. CreciFin. Prohibida la reproducción total o parcial del contenido de este sitio. </p>
                </div>
            </div>
        </div >
    );
}

export default Crecifin;
