import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Crecifin from "./components/Crecifin";
import Agricola from "./components/Agricola";
import Fabrica from "./components/Fabrica";
import Emprendimiento from "./components/Emprendimiento";
import Construccion from "./components/Construccion";
import Une from "./components/Une";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Crecifin />} />
        <Route path="/agricola" element={<Agricola />} />
        <Route path="/fabrica" element={<Fabrica />} />
        <Route path="/emprendimiento" element={<Emprendimiento />} />
        <Route path="/construccion" element={<Construccion />} />
        <Route path="/une" element={<Une />} />

      </Routes>
    </Router>
  );
}

export default App;
